.container-card{
    display: flex;
    flex-wrap: wrap;
    justify-content: space-evenly;
    margin-bottom: 6rem;
}

.card{
    display: flex;
    flex-direction: column;
    justify-content: center;
    text-align: center;
    margin-top: 5rem;
    width: 300px;
    box-shadow: 0px 0px 6px #18191b;
    border-radius: 8px;
    padding: 15px;
    transition: transform 0.3s ease;
}

.card:hover{
    transform: scale(1.1)
}

.icon-choose{
    font-size: 50px;
}

.h1-choose{
    font-size: 25px;
}

.p-choose{
    font-size: 18px;
}

