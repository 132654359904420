.container {
    max-width: 1140px;
    margin:auto;
}

.navbar-container-desktop{
    display: flex;
    align-items: center;
    padding: .2rem 1.5rem;
    justify-content: space-between;
}

.navbar-container-desktop ul{
    display: flex;
    list-style: none;
    gap: 20px;
    justify-content: flex-end;
}

.navbar{
    display: flex;
    align-items: center;
    padding: .2rem 1.5rem;
}

.icon{
    font-size: 2rem;
    color: #F3BA2F;
}

.yellow{
    color: #F3BA2F;
}

.menu-icon{
    display: none;
}

.nav-elements ul {
    display: flex;
    justify-content: space-between;
    list-style-type: none;
    gap: 0;
}

.nav-elements ul li:not(:last-child) {
    margin-right: 20px;
}


.nav-elements ul a.active {
    color: #574c4c;
    font-weight: 500;
    position: relative;
}

.nav-elements ul a.active::after {
    content: '';
    position: absolute;
    bottom: -4px;
    left: 0;
    width: 100%;
    height: 2px;
    background-color: #574c4c;
}


@media screen and (max-width: 720px) {
    .menu-icon {
        display: block;
        cursor: pointer;
    }

    .nav-elements {
        position: absolute;
        right: 0;
        top: 60px;
        background-color: #26272b;
        width: 0px;
        height: auto;
        transition: all 0.1s ease-in;
        overflow: hidden;
        box-shadow: 0px 0px 12px #18191b;
        border-radius: 8px;
        padding-bottom: 20px;
        margin-right: 5px;
    }

    .nav-elements.active {
        width: 150px;
        z-index: 1000;
    }

    .nav-elements.active > ul > li {
        text-align: center; 
        margin-right: 0px;
    }

    .nav-elements ul {
        display: flex;
        flex-direction: column;
    }

    .nav-elements ul li {
        margin-right: unset;
        margin-top: 22px;
    }
}

