footer {
    background-color: #26272b;
    width: 100%;
    height: auto;
    background-size: cover;
    background-position: center center;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
}

.footer-content {
  display: flex;
  flex-direction: column;
  width: 100%;
  margin: 0 auto;
  text-align: center;
  align-items: center;
}

.footer-content__socials {
  display: flex;
  gap: 3rem;
  color: white;
  font-size: 2rem;
}

.footer-content__socials i {
  cursor: pointer;

}

.footer-content__socials i:hover {
  transition: all 0.3s;
  background-clip: text;
  -webkit-background-clip: text;
  color: transparent;
  background-image: purple;
}

.footer-content__text {
  font-size: 18px;
  color: white;
  display: flex;
  gap: 3rem;
  margin-top: 3rem;
}

.footer-content__text p {
  cursor: pointer;
}

.footer-content__text p:hover {
  transition: all 0.3s;
  background-clip: text;
  -webkit-background-clip: text;
  color: transparent;
  background-image: purple;
}

.footer-wrap{
  flex: 1.5;
  max-width: 1540px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  flex-wrap: wrap;
  margin-top: 10px;
  gap: 10px;
  margin-bottom: 10px;
  height:auto;
  margin-left: 10px;
}


.footer-column {
  display: flex;
  flex-direction: column;
  min-width: 150px;
  text-align: left;
  margin-top: 2rem;
  height:200px;

}

.footer-header{
  font-weight: 500;
  font-size: 18px;
  line-height: 27px;
  color: white;
}

.footer-ul{
  list-style-type: none;
  margin-top: 4px;
}

.footer-link{
  font-weight: normal;
  font-size: 16px;
  line-height: 24px;
  color: #F8F8F8;
  cursor: pointer;
  
}